import { useCallback } from "react";
import Selector from "features/shared/Selector";
import { appDispatch } from "app/util";
import { setShowSegmentSelect } from "app/appSlice";
import useSegmentSelector from "./useSegmentSelector";

interface Props extends React.HTMLAttributes<HTMLSelectElement> {}

export default function SegmentSelector(props: Props) {
    const { segments, activeSegment, changeActiveSegment } =
        useSegmentSelector();
    const items =
        segments?.map((segment) => ({
            id: segment.id,
            label: segment.name,
            value: segment.id,
            custom: false,
        })) || [];
    const selectId = useCallback(
        (id: number | undefined) => {
            const activeSegment = segments.find((segment) => segment.id === id);
            changeActiveSegment(activeSegment);
            dismiss();
        },
        [segments, changeActiveSegment]
    );
    const dismiss = () => {
        appDispatch(setShowSegmentSelect(false));
    };
    return (
        <div className={props.className}>
            <Selector
                activeId={activeSegment?.id || 0}
                items={items}
                selectId={selectId}
                dismiss={dismiss}
                position="center"
                defaultLabel="All Tasks"
            />
        </div>
    );
}
