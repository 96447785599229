import React, { useLayoutEffect, useState, useCallback } from "react";
import { Popover, ArrowContainer } from "react-tiny-popover";
import classnames from "classnames";
import type { SensorReading, Unit } from "features/tasks/types";
import type { ComponentProps } from "./types";
import formStyles from "./Form.module.css";
import styles from "./NumberSlider.module.css";
import { Warning } from "icons/icons";
import { oldReading, getLastPerformedMessage } from "../functions";

interface SliderNumberProps {
    number: number;
    selected: boolean;
    id?: string;
    onSelect: (value: number) => void;
}

const SliderNumber = React.forwardRef<HTMLDivElement, SliderNumberProps>(
    (props: SliderNumberProps, ref) => {
        const onClick = (e: React.MouseEvent<HTMLElement>) => {
            props.onSelect(props.number);
        };

        let classes = [styles.number];
        if (props.selected) classes.push(styles.selected);
        let digits = Number.isInteger(props.number) ? 0 : 1;
        const isZero = props.number.toFixed(1) === "-0.0";

        return (
            <div
                ref={ref}
                className={classnames(...classes)}
                onClick={onClick}
                id={props.id}
            >
                {isZero ? "0" : props.number.toFixed(digits)}
            </div>
        );
    }
);

interface ValueProps {
    value?: string;
    unit: Unit;
    isTemperature?: boolean;
    onChange: (value: string, isActive: boolean) => void;
}

function Value(props: ValueProps) {
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange(e.target.value, true);
    };
    const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        props.onChange(e.target.value, false);
    };

    return (
        <div className={styles.temperature}>
            <input
                className={styles.value}
                value={props.value}
                onChange={onChange}
                onBlur={onBlur}
            />
            <div className={styles.unit}>
                {props.isTemperature !== false && "°"}
                {props.unit}
            </div>
        </div>
    );
}

type SensorStatusProps = {
    temperature: number;
    reading?: SensorReading;
    showPopover?: boolean;
    showHelp: () => void;
    hideHelp: () => void;
};
function SensorStatus(props: SensorStatusProps) {
    const { temperature, reading } = props;
    let classes = [styles.sensorStatus];
    let message = <>Automatic</>;
    let info;
    if (!reading || (temperature && temperature !== reading.average)) {
        classes.push(styles.sensorProblem);
        if (!reading) {
            message = <>No reading available</>;
        } else {
            message = <>Manual</>;
        }
    } else if (oldReading(reading)) {
        classes.push(styles.sensorProblem);
        message = (
            <>
                <Warning className={styles.warningIcon} /> Manual Reading
                Required
            </>
        );

        info = (
            <span className={styles.infoIcon} onClick={props.showHelp}>
                ?
            </span>
        );
        if (props.showPopover) {
            const timeInterval = getLastPerformedMessage(
                reading.timestamp,
                0,
                true
            );
            const popoverMessage = `The automated reading for this task is out of date (${timeInterval}) and is required to be completed manually.  Please check your hardware.  If the problem persists, please contact Chomp Support`;
            info = (
                <SensorPopover message={popoverMessage} hide={props.hideHelp}>
                    {info}
                </SensorPopover>
            );
        }
    }

    return (
        <div className={styles.sensorStatusContainer}>
            <div className={classnames(...classes)}>{message}</div>
            {info}
        </div>
    );
}

const _errorColour = getComputedStyle(
    document.documentElement
).getPropertyValue("--error");
type SensorPopoverProps = {
    message: string;
    children: React.ReactElement;
    hide: () => void;
};
function SensorPopover(props: SensorPopoverProps) {
    return (
        <Popover
            isOpen={true}
            positions={["right", "top", "bottom", "left"]}
            onClickOutside={props.hide}
            content={({ position, childRect, popoverRect }) => (
                <ArrowContainer
                    position={position}
                    childRect={childRect}
                    popoverRect={popoverRect}
                    arrowColor={_errorColour}
                    arrowSize={10}
                    className={styles.popoverArrowContainer}
                    arrowClassName={"popoverSensorMessageArrow"}
                >
                    <p className={styles.popoverSensorMessage}>
                        {props.message}
                    </p>
                </ArrowContainer>
            )}
        >
            {props.children}
        </Popover>
    );
}

export default function NumberSlider(props: ComponentProps) {
    let numbers = [];
    const decimalPlaces = props.increment && props.increment < 1 ? 1 : 0;
    const value = props.value !== void 0 ? props.value : "";
    const isTemperature = !props.unit || ["C", "F"].includes(props.unit);
    const [activeNumber, setActiveNumber] = useState<number | undefined>();
    const [showSensorPopover, setShowSensorPopover] = useState(false);
    // setup error message if required
    let error;
    if (props.error) {
        error = (
            <p className={formStyles.errorNumberSlider}>
                {props.error.message}
            </p>
        );
    }

    // setup problem message if required
    let problem;
    if (props.problem && props.problemError) {
        problem = <p className={formStyles.problem}>{props.problemError}</p>;
    }

    const onSelectValue = useCallback(
        (e: React.MouseEvent<HTMLElement>) => {
            const value = e.currentTarget.dataset.value;
            props.onChange(props.model, Number(value).toFixed(decimalPlaces));
            setActiveNumber(void 0);
        },
        [props, decimalPlaces]
    );

    const onChange = (value: string, isActive: boolean) => {
        if (!isActive && value) {
            const number = Number(value);
            if (Number.isFinite(number)) {
                value = number.toFixed(decimalPlaces);
            }
        }
        props.onChange(props.model, value);
    };

    let scrollToId: string | undefined;
    if (
        (props.start || props.start === 0) &&
        (props.end || props.end === 0) &&
        props.increment
    ) {
        for (let i = props.start; i < props.end + 1; i++) {
            const selectedFunc = i < 0 ? Math.ceil : Math.floor;
            const selected = props.value && selectedFunc(props.value) === i;
            const isActive = activeNumber === i;
            const key = `${props.name}_${props.model}_number_${i}`;
            let id;
            if (selected) {
                scrollToId = key;
                id = key;
            } else if (
                !scrollToId &&
                props.default &&
                Number(props.default) === i
            ) {
                scrollToId = key;
                id = key;
            }

            let number = (
                <SliderNumber
                    number={selected ? Number(props.value) : i}
                    onSelect={() => {
                        setActiveNumber(i);
                    }}
                    selected={selected}
                    key={key}
                    id={id}
                />
            );

            if (isActive) {
                let popoverList: React.ReactNode[] = [];
                let temp = i;
                let count = 10;
                let increment =
                    i < 0 || props.end === 0
                        ? -props.increment
                        : props.increment;
                if (props.start < 0 && props.end > 0 && i === 0) {
                    temp = 0.9;
                    count = 19;
                    increment = -0.1;
                }

                for (let j = 0; j < count; j++) {
                    let itemValue = temp.toFixed(decimalPlaces);
                    if (j > 0) {
                        itemValue = (temp += increment).toFixed(decimalPlaces);
                    }
                    const popoverNumberKey = `${props.name}_number_popover_${j}`;
                    popoverList.push(
                        <li
                            data-value={itemValue}
                            onClick={onSelectValue}
                            key={popoverNumberKey}
                        >
                            {itemValue}
                        </li>
                    );
                }

                const popoverKey = `${props.name}_number_popover`;
                number = (
                    <Popover
                        key={popoverKey}
                        isOpen={true}
                        onClickOutside={() => {
                            setActiveNumber(void 0);
                        }}
                        positions={["right", "left"]}
                        content={({ position, childRect, popoverRect }) => (
                            <ArrowContainer
                                position={position}
                                childRect={childRect}
                                popoverRect={popoverRect}
                                arrowColor={"#fff"}
                                arrowSize={10}
                                className={styles.popoverArrowContainer}
                                arrowClassName={"popoverArrow"}
                            >
                                <div className={styles.popoverList}>
                                    <ul>{popoverList}</ul>
                                </div>
                            </ArrowContainer>
                        )}
                    >
                        {number}
                    </Popover>
                );
            }

            numbers.push(number);
        }
    }

    useLayoutEffect(() => {
        if (scrollToId) {
            const scrollToElement = document.getElementById(scrollToId);
            if (scrollToElement) {
                scrollToElement.scrollIntoView({
                    inline: "center",
                });
            }
        }
    }, [scrollToId]);

    const classes = [styles.controls];
    if (props.error) {
        classes.push(styles.error);
    } else if (props.problem) {
        classes.push(styles.problem);
    } else if (props.value !== undefined) {
        classes.push(styles.valid);
    }

    let sensorTag;
    if (props.hasSensor) {
        const showHelp = () => {
            setShowSensorPopover(true);
        };
        const hideHelp = () => {
            setShowSensorPopover(false);
        };
        sensorTag = (
            <SensorStatus
                temperature={Number(props.value)}
                reading={props.sensorReading}
                showPopover={showSensorPopover}
                showHelp={showHelp}
                hideHelp={hideHelp}
            />
        );
    }
    return (
        <>
            {props.label && (
                <div className={styles.header}>
                    <h3 className={styles.label}>{props.label}</h3>
                    {sensorTag}
                </div>
            )}

            {problem}

            <div className={styles.NumberSlider}>
                <div className={classnames(...classes)}>
                    <div className={styles.slider}>{numbers}</div>
                    <Value
                        value={value}
                        unit={props.unit || "C"}
                        isTemperature={isTemperature}
                        onChange={onChange}
                    />
                </div>
                {error}
            </div>
        </>
    );
}
