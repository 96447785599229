import type { AppStartListening } from "app/listeners";
import {
    appSlice,
    addAppToast,
    setUrlPath,
    selectAppToasts,
    removeAppToast,
} from "app/appSlice";
import {
    appServiceApi,
    getServerError,
    selectRefreshTokenQueryState,
} from "services/appService";
import type { ServerEndpoint } from "app/appSlice";
import {
    loginTokenSlice,
    setLoginError,
    setUserVenues,
    setBearerToken,
    selectFernetToken,
    setTokenSource,
    setShowLogin,
    selectBearerToken,
} from "./loginTokenSlice";
import type { loginErrorType } from "./loginTokenSlice";
import { parseQrCodeData } from "./useLoginToken";
import { setFernetToken, selectTokenSource } from "./loginTokenSlice";
import { resetState, setServerError, selectServerErrors } from "app/appSlice";
import { InitiatedPasswordResetAction } from "./usePasswordReset";
import { PayloadAction } from "@reduxjs/toolkit";
import { showRoute } from "app/routeListeners";

export const startUrlPathListening = (startListening: AppStartListening) => {
    startListening({
        actionCreator: appSlice.actions.setUrlPath,
        effect: (action, listenerApi) => {
            const [fernetToken, tokenSource] =
                parseQrCodeData(action.payload.url) || [];
            if (fernetToken) {
                listenerApi.dispatch(resetState());
                listenerApi.dispatch(setFernetToken(fernetToken));
                listenerApi.dispatch(setTokenSource(tokenSource));
                listenerApi.dispatch(setUrlPath({ url: "/", processed: true }));
            }
        },
    });
};

export const startSetFernetTokenListening = (
    startListening: AppStartListening
) => {
    startListening({
        actionCreator: loginTokenSlice.actions.setFernetToken,
        effect: (action, listenerApi) => {
            listenerApi.dispatch(setShowLogin(false));
            if (action.payload) {
                listenerApi.dispatch(
                    appServiceApi.endpoints.getVenues.initiate(void 0, {
                        forceRefetch: true,
                    })
                );
            }
        },
    });
};

export const startGetBearerTokenListening = (
    startListening: AppStartListening
) => {
    startListening({
        matcher: appServiceApi.endpoints.getBearerToken.matchFulfilled,
        effect: (action, listenerApi) => {
            listenerApi.dispatch(setBearerToken(action.payload));
            if (action.payload) {
                listenerApi.dispatch(
                    appServiceApi.endpoints.getVenues.initiate(void 0, {
                        forceRefetch: true,
                    })
                );
                listenerApi.dispatch(
                    appServiceApi.endpoints.getProfile.initiate(void 0, {
                        forceRefetch: true,
                    })
                );
                listenerApi.dispatch(setUrlPath({ url: "/", processed: true }));
            }
        },
    });

    startListening({
        matcher: appServiceApi.endpoints.getBearerToken.matchRejected,
        effect: (action, listenerApi) => {
            if (!action.payload) return;

            const data = action.payload?.data;
            let errorMessage: string | undefined =
                "There was an error logging in";
            let errorType: loginErrorType = "app";
            if (data instanceof Object && "error" in data) {
                switch (data["error"]) {
                    case "client_error":
                    case "invalid_grant":
                        errorMessage =
                            "Your email or password might be incorrect. Double-check and try again, or click 'Forgotten your password?' to reset your password.";
                        errorType = "user";
                        listenerApi.dispatch(setBearerToken());
                        break;
                    case "change_password":
                        if ("url" in data) {
                            const password =
                                action.meta.arg.originalArgs.password;
                            const redirectString = data["url"] as string;
                            const redirectUrl = new URL(redirectString);
                            redirectUrl.searchParams.set("password", password);
                            listenerApi.dispatch(
                                setUrlPath({
                                    url:
                                        redirectUrl.pathname +
                                        redirectUrl.search,
                                })
                            );
                            errorMessage = void 0;
                        }
                }
            }
            let loginError;
            if (errorMessage) loginError = { errorMessage, type: errorType };
            listenerApi.dispatch(setLoginError(loginError));
            listenerApi.dispatch(setUserVenues());
        },
    });
};

const startRefreshTokenListening = (startListening: AppStartListening) => {
    startListening({
        matcher: appServiceApi.endpoints.refreshToken.matchFulfilled,
        effect: (action, listenerApi) => {
            listenerApi.dispatch(setBearerToken(action.payload));
        },
    });

    startListening({
        matcher: appServiceApi.endpoints.refreshToken.matchRejected,
        effect: (action, listenerApi) => {
            const error = getServerError(action);
            listenerApi.dispatch(setServerError(["refeshToken", error]));
        },
    });
};

export const startUserVenuesListening = (startListening: AppStartListening) => {
    startListening({
        matcher: appServiceApi.endpoints.getVenues.matchFulfilled,
        effect: (action, listenerApi) => {
            listenerApi.dispatch(setUserVenues(action.payload));
            if (action.payload.length === 0) {
                listenerApi.dispatch(
                    setLoginError({
                        errorMessage: "No venues available for this user",
                        type: "app",
                    })
                );
            }
            listenerApi.dispatch(setServerError(["venue", void 0]));
        },
    });

    startListening({
        matcher: appServiceApi.endpoints.getVenues.matchRejected,
        effect: (action, listenerApi) => {
            if (!action.payload) return;

            const data = action.payload?.data;
            let errorMessage = "Unable to load user venues";
            let errorType: loginErrorType = "app";
            if (data instanceof Object && "error" in data) {
                const tokenSource = selectTokenSource(listenerApi.getState());
                switch (data["error"]) {
                    case "invalid_header":
                        switch (tokenSource) {
                            case "migrate":
                                errorMessage = "Invalid migration code.";
                                break;
                            case "qrcode":
                                errorMessage = "Invalid QR code.";
                                break;
                            case "link":
                                errorMessage = "Invalid login link.";
                                break;
                        }
                        errorType = "app";
                        break;
                }
            }

            const serverError = getServerError(action);
            listenerApi.dispatch(
                setLoginError({
                    error: serverError,
                    errorMessage,
                    type: errorType,
                })
            );
            listenerApi.dispatch(setBearerToken());
            listenerApi.dispatch(setFernetToken());
            listenerApi.dispatch(setServerError(["venue", serverError]));
        },
    });
};

export const startInitiatePasswordResetListening = (
    startListening: AppStartListening
) => {
    startListening({
        type: InitiatedPasswordResetAction,
        effect: (action, listenerApi) => {
            const payloadAction = action as PayloadAction<string>;
            listenerApi.dispatch(
                appServiceApi.endpoints.initiatePasswordReset.initiate(
                    payloadAction.payload
                )
            );
        },
    });

    startListening({
        matcher: appServiceApi.endpoints.initiatePasswordReset.matchFulfilled,
        effect: (action, listenerApi) => {
            listenerApi.dispatch(showRoute("passwordResetInitiated"));
            listenerApi.dispatch(setServerError(["password", void 0]));
        },
    });

    startListening({
        matcher: appServiceApi.endpoints.initiatePasswordReset.matchRejected,
        effect: (action, listenerApi) => {
            const error = getServerError(action);
            listenerApi.dispatch(setServerError(["password", error]));
        },
    });
};

const AUTO_LOGOUT_EXEMPTIONS: Set<ServerEndpoint> = new Set([
    "profile",
    "password",
]);
let _shownServerErrorToast = false;
export const startServerErrorListening = (
    startListening: AppStartListening
) => {
    startListening({
        actionCreator: appSlice.actions.setServerError,
        effect: (action, listenerApi) => {
            const [endpoint, error] = action.payload;
            const serverErrors = selectServerErrors(listenerApi.getState());
            const unauthorisedErrors = serverErrors
                ? Object.values(serverErrors).filter(
                      (error) =>
                          error.httpCode === 401 || error.httpCode === 403
                  )
                : [];
            const toasts = selectAppToasts(listenerApi.getState()) || [];
            let loggedOutToasts = toasts.filter((toast) =>
                toast.id.startsWith("logged_out_")
            );
            // We only want to show the toast once - the first time a 401/403
            // error is returned.
            // serverErrors will have the current error already added hence
            // check for length === 1
            if (
                !AUTO_LOGOUT_EXEMPTIONS.has(endpoint) &&
                (error?.httpCode === 401 || error?.httpCode === 403) &&
                loggedOutToasts.length === 0 &&
                !_shownServerErrorToast
            ) {
                // Only show the logged-out toast if they were logged in
                const token = selectFernetToken(listenerApi.getState());
                if (token) {
                    if (loggedOutToasts.length === 0) {
                        listenerApi.dispatch(
                            addAppToast({
                                id: `logged_out_` + new Date().getTime(),
                                title: `You have been automatically logged out. Please login again.`,
                                created: new Date().toISOString(),
                                type: "warning",
                                permanent: true,
                            })
                        );
                        _shownServerErrorToast = true;
                    }
                }
            } else if (error?.httpCode === 400) {
                // Bearer token has expired, so get a new one with the refresh token
                const bearerToken = selectBearerToken(listenerApi.getState());
                if (bearerToken?.refreshToken) {
                    const refreshTokenQueryState = selectRefreshTokenQueryState(
                        listenerApi.getState(),
                        bearerToken
                    );
                    if (
                        !refreshTokenQueryState?.isFetching &&
                        !refreshTokenQueryState?.isLoading
                    ) {
                        listenerApi.dispatch(
                            appServiceApi.endpoints.refreshToken.initiate(
                                bearerToken
                            )
                        );
                    }
                }
            } else if (
                unauthorisedErrors.length === 0 &&
                loggedOutToasts.length > 0
            ) {
                // If there's no 401/403 errors, hide any logged-out toasts
                // as the user will have been logged-in again
                loggedOutToasts.forEach((toast) => {
                    listenerApi.dispatch(removeAppToast(toast));
                });
            }

            if (unauthorisedErrors.length === 0) _shownServerErrorToast = false;
        },
    });
};

const listeners = [
    startUrlPathListening,
    startUserVenuesListening,
    startSetFernetTokenListening,
    startGetBearerTokenListening,
    startRefreshTokenListening,
    startServerErrorListening,
    startInitiatePasswordResetListening,
];
export default listeners;
