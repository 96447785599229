import { getStore } from "app/store";
import { setServerError, setServerErrors } from "app/appSlice";
import type { ServerEndpoint } from "app/appSlice";

export default function addServerError(
    errorCode?: number,
    endpoint?: ServerEndpoint
) {
    const error = {
        httpCode: errorCode || 500,
        data: { message: "triggerServerError error" },
        datetime: new Date().toISOString(),
    };
    getStore().dispatch(setServerError([endpoint || "profile", error]));
}

export function resetServerErrors() {
    getStore().dispatch(setServerErrors({}));
}
