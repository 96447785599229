import { PayloadAction } from "@reduxjs/toolkit";
import { DateTime } from "luxon";
import { getStore, RootState } from "./store";
import {
    selectUniqueId,
    setUniqueId,
    selectBranchName,
} from "features/environment/envSlice";
import { generateUniqueId } from "features/environment/util";
import { version } from "VERSION";
import { selectProfile } from "features/loginToken/loginTokenSlice";
import { selectStaffWithEmail } from "features/staff/staffSlice";
import { selectActiveUser } from "./appSlice";
import { selectHasBeenIdle } from "./appSlice";

export function appDispatch<T>(action: PayloadAction<T>) {
    getStore().dispatch(action);
}

export function appSelect<T>(selector: (state: RootState) => T): T {
    const state = getStore().getState();

    return selector(state);
}

export function appSelectArg<T, S>(
    selector: (state: RootState, arg: S) => T,
    arg: S
): T {
    const state = getStore().getState();

    return selector(state, arg);
}

export function getUniqueId(): string {
    let uniqueId = appSelect(selectUniqueId);
    if (!uniqueId) {
        uniqueId = generateUniqueId();
        appDispatch(setUniqueId(uniqueId));
    }
    return uniqueId;
}

export function getBuildVersion(): string {
    let buildVersion = `${version}`;
    const branchName = appSelect(selectBranchName);
    if (branchName) {
        buildVersion += `-${branchName}`;
    }

    return buildVersion;
}

export function viewportHeightAvailable(
    element: HTMLElement
): number | undefined {
    let viewportHeight = window.visualViewport?.height;
    let availableHeight;
    if (viewportHeight) {
        availableHeight = viewportHeight - element.offsetTop;
    }
    return availableHeight;
}

export function sqlDatetime(date: Date): string {
    return DateTime.fromJSDate(date).toFormat("yyyy-MM-dd HH:mm:ss");
}

export function isTestServer(): boolean {
    return window.location.hostname.includes("test");
}

export function isTesting(): boolean {
    let isTesting = false;
    try {
        isTesting = process.env.JEST_WORKER_ID !== undefined;
    } catch (e) {}
    return isTesting;
}

// Dumb pluralisation function which works for our current needs.
export function pluralise(string: string, count: number): string {
    if (count === 1) {
        return string;
    } else {
        return string + "s";
    }
}

export const isValidEmail = (email: string): boolean => {
    return /^\S+@\S+$/.test(email);
};

export const yearsAgoDate = (years: number): string => {
    const currentDate = new Date();
    currentDate.setFullYear(currentDate.getFullYear() - years);
    const yearsAgo = currentDate.toISOString().split("T")[0];

    return yearsAgo;
};

export function getQueryParams(url?: string) {
    let queryParams: Record<string, string> = {};
    let searchParams;
    if (url) {
        searchParams = new URL(url).searchParams;
    } else if (window) {
        searchParams = new URLSearchParams(window.location.search);
    }

    if (searchParams) {
        for (let param of searchParams) {
            queryParams[param[0]] = param[1];
        }
    }

    return queryParams;
}

// prettier-ignore
const countryCodes = [
    "AF","AL","DZ","AS","AD","AO","AI","AQ","AG","AR","AM","AW","AU","AT","AZ","BS","BH","BD","BB","BY","BE","BZ","BJ","BM","BT","BO","BQ","BA","BW","BV","BR","IO","BN","BG","BF","BI","CV","KH","CM","CA","KY","CF","TD","CL","CN","CX","CC","CO","KM","CD","CG","CK","CR","HR","CU","CW","CY","CZ","CI","DK","DJ","DM","DO","EC","EG","SV","GQ","ER","EE","SZ","ET","FK","FO","FJ","FI","FR","GF","PF","TF","GA","GM","GE","DE","GH","GI","GR","GL","GD","GP","GU","GT","GG","GN","GW","GY","HT","HM","VA","HN","HK","HU","IS","IN","ID","IR","IQ","IE","IM","IL","IT","JM","JP","JE","JO","KZ","KE","KI","KP","KR","KW","KG","LA","LV","LB","LS","LR","LY","LI","LT","LU","MO","MG","MW","MY","MV","ML","MT","MH","MQ","MR","MU","YT","MX","FM","MD","MC","MN","ME","MS","MA","MZ","MM","NA","NR","NP","NL","NC","NZ","NI","NE","NG","NU","NF","MP","NO","OM","PK","PW","PS","PA","PG","PY","PE","PH","PN","PL","PT","PR","QA","MK","RO","RU","RW","RE","BL","SH","KN","LC","MF","PM","VC","WS","SM","ST","SA","SN","RS","SC","SL","SG","SX","SK","SI","SB","SO","ZA","GS","SS","ES","LK","SD","SR","SJ","SE","CH","SY","TW","TJ","TZ","TH","TL","TG","TK","TO","TT","TN","TR","TM","TC","TV","UG","UA","AE","GB","UM","US","UY","UZ","VU","VE","VN","VG","VI","WF","EH","YE","ZM","ZW","AX",
];

type IntlCode = { code: string; name: string };

export function getCountryList(lang = "en"): IntlCode[] {
    let countryList: IntlCode[] = [];
    if (typeof Intl.DisplayNames === "undefined") {
        console.error("Intl.DisplayNames is not supported in this browser.");
    } else {
        const regionNames = new Intl.DisplayNames([lang], { type: "region" });

        for (let code of countryCodes) {
            const name = regionNames.of(code);
            if (name) {
                countryList.push({
                    code,
                    name,
                });
            }
        }
    }

    return countryList;
}

// prettier-ignore
export const languageCodes = [
    "af", "sq", "am", "ar", "hy", "az", "eu", "be", "bn", "bs", "bg", "ca", "ceb", "zh-Hans", "zh-Hant", "co", "hr", "cs", "da", "nl", "en", "en-NZ", "en-AU", "en-GB", "en-US", "eo", "et", "fi", "fr", "fy", "gl", "ka", "de", "el", "gu", "ht", "ha", "haw", "he", "hi", "hmn", "hu", "is", "ig", "id", "ga", "it", "ja", "jv", "kn", "kk", "km", "rw", "ko", "ku", "ky", "lo", "la", "lv", "lt", "lb", "mk", "mg", "ms", "ml", "mt", "mi", "mr", "mn", "my", "ne", "no", "ny", "or", "ps", "fa", "pl", "pt", "pa", "ro", "ru", "sm", "gd", "sr", "st", "sn", "sd", "si", "sk", "sl", "so", "es", "su", "sw", "sv", "tl", "tg", "ta", "tt", "te", "th", "tr", "tk", "uk", "ur", "ug", "uz", "vi", "cy", "xh", "yi", "yo", "zu"
];

export function getLanguageList(lang = "en"): IntlCode[] {
    let languageList: IntlCode[] = [];
    if (typeof Intl.DisplayNames === "undefined") {
        console.error("Intl.DisplayNames is not supported in this browser.");
    } else {
        const languageNames = new Intl.DisplayNames([lang], {
            type: "language",
        });

        for (let code of languageCodes) {
            const name = languageNames.of(code);
            if (name) {
                languageList.push({
                    code,
                    name,
                });
            }
        }
    }

    return languageList;
}

export function selectActiveUserId(
    state: RootState,
    venueId: number
): number | undefined {
    let activeUserId = appSelect(selectActiveUser);
    const profile = appSelect(selectProfile);
    if (profile) {
        let loggedInStaff = appSelect((state) =>
            selectStaffWithEmail(state, venueId, profile.email)
        );
        if (loggedInStaff) activeUserId = loggedInStaff.id;
    } else if (selectHasBeenIdle(state)) {
        activeUserId = void 0;
    }

    return activeUserId;
}

interface CacheItem<T> {
    value: T;
    expiry: number;
    timestamp: number;
}

export class Cache<T> {
    private cache: Map<any, CacheItem<T>>;

    constructor() {
        this.cache = new Map<any, CacheItem<T>>();
    }

    set(key: any, value: T, timeInSeconds: number): void {
        this.cache.set(key, {
            value,
            expiry: Date.now() + timeInSeconds * 1000,
            timestamp: Date.now(),
        });
    }

    get(key: any): T | undefined {
        const item = this.cache.get(key);
        if (!item) return undefined;

        if (Date.now() > item.expiry) {
            this.cache.delete(key);
            return undefined;
        }

        return item.value;
    }

    clear(): void {
        this.cache.clear();
    }
}
