import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import type { UserVenueData } from "./types";
import type { BearerToken } from "services/appService";
import type { Profile } from "./types";
import { ServerError } from "app/appSlice";

export type loginType = "qrCode" | "password";
export type tokenSource = "qrcode" | "migrate" | "link";
export interface ResetPasswordCredential {
    token: string;
    uuid: string;
}
export type loginErrorType = "user" | "app";

export interface LoginError {
    error?: ServerError;
    errorMessage: string;
    type: loginErrorType;
}

export interface LoginTokenState {
    fernetToken?: string;
    bearerToken?: BearerToken;
    tokenSource?: tokenSource;
    cameraEnabled: boolean;
    loginType: loginType;
    loginError?: LoginError;
    userVenues?: UserVenueData[];
    showLogin: boolean;
    profile?: Profile;
    resetPasswordCredential?: ResetPasswordCredential;
}

const initialState: LoginTokenState = {
    cameraEnabled: true,
    loginType: "qrCode",
    showLogin: false,
};

export const loginTokenSlice = createSlice({
    name: "loginToken",
    initialState,
    reducers: {
        setFernetToken: (
            state: LoginTokenState,
            action: PayloadAction<string | undefined>
        ) => {
            state.fernetToken = action.payload;
        },
        setBearerToken: (
            state: LoginTokenState,
            action: PayloadAction<BearerToken | undefined>
        ) => {
            state.bearerToken = action.payload;
        },
        setTokenSource: (
            state: LoginTokenState,
            action: PayloadAction<tokenSource | undefined>
        ) => {
            state.tokenSource = action.payload;
        },
        setCameraEnabled: (
            state: LoginTokenState,
            action: PayloadAction<boolean>
        ) => {
            state.cameraEnabled = action.payload;
        },
        setLoginType: (
            state: LoginTokenState,
            action: PayloadAction<loginType>
        ) => {
            state.loginType = action.payload;
        },
        setLoginError: (
            state: LoginTokenState,
            action: PayloadAction<LoginError | undefined>
        ) => {
            state.loginError = action.payload;
        },
        setUserVenues: (
            state: LoginTokenState,
            action: PayloadAction<UserVenueData[] | undefined>
        ) => {
            state.userVenues = action.payload;
        },
        setShowLogin: (
            state: LoginTokenState,
            action: PayloadAction<boolean>
        ) => {
            state.showLogin = action.payload;
        },
        resetState: (state, action: PayloadAction) => {
            return {
                ...initialState,
                cameraEnabled: state.cameraEnabled,
                loginType: state.loginType,
            };
        },
        setProfile: (state, action: PayloadAction<Profile>) => {
            state.profile = action.payload;
        },
        setResetPasswordCredential: (
            state,
            action: PayloadAction<ResetPasswordCredential>
        ) => {
            state.resetPasswordCredential = action.payload;
        },
    },
});

export const {
    setFernetToken,
    setBearerToken,
    setTokenSource,
    setCameraEnabled,
    setLoginType,
    setLoginError,
    setUserVenues,
    setShowLogin,
    resetState,
    setProfile,
    setResetPasswordCredential,
} = loginTokenSlice.actions;

export const selectFernetToken = (state: RootState): string | undefined => {
    return state.loginToken.fernetToken;
};
export const selectBearerToken = (
    state: RootState
): BearerToken | undefined => {
    return state.loginToken.bearerToken;
};
export const selectTokenSource = (
    state: RootState
): tokenSource | undefined => {
    return state.loginToken.tokenSource;
};
export const selectIsLoggedIn = (state: RootState): boolean =>
    !selectUnauthorised(state) &&
    (!!state.loginToken.fernetToken || !!state.loginToken.bearerToken) &&
    !state.loginToken.loginError &&
    !!state.loginToken.userVenues &&
    state.loginToken.userVenues?.length > 0;
export const selectCameraEnabled = (state: RootState) =>
    state.loginToken.cameraEnabled;
export const selectLoginType = (state: RootState) => state.loginToken.loginType;
export const selectLoginError = (state: RootState) =>
    state.loginToken.loginError;
export const selectUserVenues = (state: RootState) =>
    state.loginToken.userVenues;
export const selectShowLogin = (state: RootState) => state.loginToken.showLogin;
export const selectUnauthorised = (state: RootState) => {
    let isUnauthorised = false;
    if (state.app.serverErrors) {
        for (const error of Object.values(state.app.serverErrors)) {
            if (error.httpCode === 401 || error.httpCode === 403) {
                isUnauthorised = true;
                break;
            }
        }
    }
    return isUnauthorised;
};
export const selectProfile = (state: RootState) => state.loginToken.profile;
export const selectResetPasswordCredential = (state: RootState) =>
    state.loginToken.resetPasswordCredential;

export default loginTokenSlice.reducer;
