import Alert from "react-bootstrap/Alert";
import { isTestServer } from "app/util";
import styles from "./LoginError.module.scss";
import type { LoginError } from "./loginTokenSlice";

interface Props {
    loginError?: LoginError;
    tokenSource?: string;
}

const CHEF_SERVER = "chef.chomp.nz";
const CHEF_TEST_SERVER = "chef.test.chomp.nz";

export default function LoginErrorView(props: Props) {
    const contactMessage = (
        <p className={styles.support}>
            If problems persist, please get in contact with{" "}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.chompfoodsafety.com/customer-support"
            >
                our helpful support team
            </a>
            .
        </p>
    );
    const isAuthError = props.loginError?.error?.httpCode === 401;
    let errorMessage;
    if (isAuthError && props.tokenSource === "migrate") {
        const server = isTestServer() ? CHEF_TEST_SERVER : CHEF_SERVER;
        errorMessage = (
            <Alert variant="warning">
                <p className={styles.container}>
                    There has been an error migrating this device: <br />
                    <span className={styles.loginError}>
                        {props.loginError?.errorMessage}
                    </span>
                </p>

                <p className={styles.container}>
                    Please try migrating again from{" "}
                    <a href={`https://${server}`}>
                        your old version of the Chomp app
                    </a>{" "}
                    if you have a QR code, try scanning your QR code using the
                    button below.
                </p>

                {contactMessage}
            </Alert>
        );
    } else if (isAuthError && props.tokenSource === "qrcode") {
        errorMessage = (
            <div className={styles.loginErrorView}>
                <img
                    src="/chef_hat_broken_reduced.png"
                    className={styles.errorImage}
                    alt=""
                />
                <h2>There has been an error logging in via QR Code scan</h2>

                <p>
                    Please try again by scanning your QR code using the button
                    below.
                </p>

                {contactMessage}
            </div>
        );
    } else if (isAuthError && props.tokenSource === "link") {
        errorMessage = (
            <div className={styles.loginErrorView}>
                <img
                    src="/chef_hat_broken_reduced.png"
                    className={styles.errorImage}
                    alt=""
                />
                <p>There has been an error logging in via a link.</p>

                <p>Please try clicking the link again.</p>

                {contactMessage}
            </div>
        );
    } else if (props.loginError?.type === "user") {
        errorMessage = (
            <>
                <p>User: {props.loginError?.errorMessage}</p>
            </>
        );
    }

    let loginErrorComponent;
    if (errorMessage) {
        loginErrorComponent = errorMessage;
    } else if (props.loginError?.error?.httpCode === 402) {
        loginErrorComponent = (
            <div className={styles.loginErrorView}>
                <img
                    src="/chef_hat_suspended_reduced.png"
                    className={styles.errorImage}
                    alt=""
                />
                <h2>Your account is on hold!</h2>
                <p>
                    Oops! It looks like your account is on hold due to an
                    outstanding balance.
                </p>

                <p>
                    No worries - just settle up, and you'll be back in action in
                    no time!
                </p>

                <p className={styles.support}>
                    Need help? Contact{" "}
                    <a href="mailto:support@chompfoodsafety.com">
                        support@chompfoodsafety.com
                    </a>
                </p>
            </div>
        );
    } else if (props.loginError?.error?.httpCode === 403) {
        loginErrorComponent = (
            <div className={styles.loginErrorView}>
                <img
                    src="/chef_hat_sweat_reduced.png"
                    className={styles.errorImage}
                    alt=""
                />
                <h2>Your Account is Suspended!</h2>
                <p>
                    It looks like you no longer have an active account with us.
                </p>
                <p>
                    Contact us if you would like to reactive your account or
                    find out more.
                </p>
                <p className={styles.support}>
                    email:{" "}
                    <a href="mailto:support@chompfoodsafety.com">
                        support@chompfoodafety.com
                    </a>
                    <br />
                    phone: <a href="tel:+6437467399">+64 3 746 7399</a>
                </p>
            </div>
        );
    } else {
        loginErrorComponent = (
            <div className={styles.loginErrorView}>
                <img
                    src="/chef_hat_tradie_reduced.png"
                    className={styles.errorImage}
                    alt=""
                />
                <h2>Oops! </h2>
                <p>
                    We're experiencing a temporary glitch. Don't worry, we're
                    onto it!
                </p>
                <p>Please try again in a few minutes.</p>
            </div>
        );
    }

    return loginErrorComponent;
}
