import React, { useCallback } from "react";
import { DataSourceItem } from "features/tasks/form/types";
import styles from "./Selector.module.scss";
import classnames from "classnames";

type Position = "left" | "center" | "right";

interface Props {
    activeId: number;
    items: Required<DataSourceItem>[];
    position?: Position;
    defaultLabel?: string;
    selectId: (id?: number) => void;
    dismiss: () => void;
}

export default function Selector(props: Props) {
    const { items, activeId, selectId, dismiss } = props;
    let position = props.position || "left";

    const handleClick = useCallback(
        (e: React.MouseEvent<HTMLLIElement>) => {
            let itemId;
            if (e && e.currentTarget.dataset.itemId) {
                itemId = Number(e.currentTarget.dataset.itemId);
            }
            selectId(itemId);
        },
        [selectId]
    );

    let menuItems = [];
    menuItems.push(
        <li
            onClick={handleClick}
            key="selector_null"
            className={!props.activeId ? styles.activeItem : ""}
        >
            {props.defaultLabel || "----"}
        </li>
    );
    for (let item of items) {
        const key = "selector_" + item.id;
        const className = activeId === item.id ? styles.activeItem : "";
        menuItems.push(
            <li
                className={className}
                data-item-id={item.id}
                onClick={handleClick}
                key={key}
            >
                {item.label}
            </li>
        );
    }

    const className = classnames(styles.selector, styles[position]);

    return (
        <div
            className={styles.container}
            onClick={(e) => {
                if (e.target === e.currentTarget) {
                    dismiss();
                }
            }}
        >
            <div className={className}>
                <ul className={styles.itemList}>{menuItems}</ul>
            </div>
        </div>
    );
}
