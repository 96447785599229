import refreshToken from "./refreshToken";
import logDebugData from "./logDebugData";
import addServerError, { resetServerErrors } from "./addServerError";

const commands = [
    refreshToken,
    logDebugData,
    addServerError,
    resetServerErrors,
];
let _global = window as any;

export default function setupCommands() {
    let chomp: { [key: string]: any } = {};
    for (let command of commands) {
        chomp[command.name] = command;
    }
    _global.chomp = chomp;
}
