import useLoginToken from "./useLoginToken";
import type { FormData } from "./useLoginToken";
import styles from "./Login.module.scss";
import formStyles from "app/form.module.scss";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "features/tasks/form/Button";
import loaderStyles from "app/Loader.module.css";
import LoginErrorView from "./LoginErrorView";
import FormErrorMessage from "app/FormError";
import QRCodeScanner from "./QRCodeScanner";
import LoginButtons from "./LoginButtons";
import { LoginError } from "./loginTokenSlice";
import { Eye, EyeSlash } from "icons/icons";
import iconStyles from "icons/Icon.module.css";

interface Props {
    loginError?: LoginError;
}

export function Login(props: Props) {
    const {
        loginType,
        loginError,
        passwordLoginState,
        getVenuesQueryState,
        tokenSource,
        showLogin,
        queryEmail,
        queryPassword,
        errors,
        showPassword,
        register,
        handleSubmit,
        handleCancel,
        loginWithPassword,
        processQrResult,
        validateEmail,
        toggleShowPassword,
    } = useLoginToken();
    const error = loginError || props.loginError;
    const onSubmit = (data: FormData) => {
        loginWithPassword();
    };

    let loginErrorView;
    if (error && error.type === "app") {
        loginErrorView = (
            <LoginErrorView
                loginError={loginError || props.loginError}
                tokenSource={tokenSource}
            />
        );
    } else if (error && error.type === "user") {
        loginErrorView = (
            <div className={formStyles.error}>{error.errorMessage}</div>
        );
    }

    let loginComponent;
    if (showLogin) {
        if (loginType === "qrCode") {
            loginComponent = (
                <>
                    {!getVenuesQueryState?.isLoading && (
                        <QRCodeScanner
                            processQrResult={processQrResult}
                            getVenuesQueryState={getVenuesQueryState}
                        />
                    )}
                    {getVenuesQueryState?.isLoading && (
                        <div className={styles.loginLoader}>
                            <div className={loaderStyles.Loader}>
                                <img
                                    src="/ChompSpinner.svg"
                                    alt="Logging in..."
                                />
                            </div>
                        </div>
                    )}
                </>
            );
        } else {
            loginComponent = (
                <div className={styles.venueKeyForm}>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group controlId="loginEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                defaultValue={queryEmail}
                                {...register("username", {
                                    required: true,
                                    validate: validateEmail,
                                })}
                            />
                            <FormErrorMessage error={errors.username} />
                        </Form.Group>
                        <Form.Group controlId="loginPassword">
                            <Form.Label>Password</Form.Label>
                            <InputGroup>
                                <Form.Control
                                    type={showPassword ? "text" : "password"}
                                    defaultValue={queryPassword}
                                    {...register("password", {
                                        required: true,
                                    })}
                                />
                                <InputGroup.Text onClick={toggleShowPassword}>
                                    {!showPassword && (
                                        <Eye className={iconStyles.largeIcon} />
                                    )}
                                    {showPassword && (
                                        <EyeSlash
                                            className={iconStyles.largeIcon}
                                        />
                                    )}
                                </InputGroup.Text>
                            </InputGroup>
                            <FormErrorMessage error={errors.password} />
                        </Form.Group>

                        {loginErrorView}

                        {(!loginErrorView || error?.type === "user") && (
                            <>
                                <a
                                    href="/login/password_reset"
                                    className={styles.passwordReset}
                                >
                                    Forgotten your password?
                                </a>
                                {!passwordLoginState?.isLoading && (
                                    <>
                                        <Button type="submit">Sign In</Button>

                                        <Button
                                            onClick={handleCancel}
                                            className={styles.cancel}
                                        >
                                            Choose another login method
                                        </Button>
                                    </>
                                )}
                                {passwordLoginState?.isLoading && (
                                    <div className={styles.loginLoader}>
                                        <div className={loaderStyles.Loader}>
                                            <img
                                                src="/ChompSpinner.svg"
                                                alt="Logging in..."
                                            />
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    </Form>
                </div>
            );
        }
    } else {
        loginComponent = <LoginButtons>{loginErrorView}</LoginButtons>;
    }

    let loginScreen;
    if (getVenuesQueryState?.isLoading) {
        loginScreen = (
            <>
                <h2>
                    <img
                        src="/Chomp-Logo.png"
                        alt="Chomp"
                        className={styles.logo}
                    />
                </h2>
                <div className={styles.loginLoader}>
                    <div className={loaderStyles.Loader}>
                        <img src="/ChompSpinner.svg" alt="Logging in..." />
                    </div>
                </div>
            </>
        );
    } else {
        loginScreen = (
            <>
                <h2>
                    <img
                        src="/Chomp-Logo-simplified.png"
                        alt="Chomp"
                        className={styles.logo}
                    />
                </h2>
                {loginComponent}
            </>
        );
    }

    return <div className="loginScreen">{loginScreen}</div>;
}
