import { useCallback } from "react";
import { useAppSelector, useAppDispatch } from "app/hooks";
import { selectSegments } from "./tasksSlice";
import {
    selectActiveSegment,
    selectActiveVenueId,
    setActiveSegment,
} from "app/appSlice";
import type { Segment } from "./types";

interface SegmentSelectorData {
    segments: Segment[];
    activeSegment?: Segment;
    changeActiveSegment: (segment?: Segment) => void;
}

export default function useSegmentSelector(): SegmentSelectorData {
    const venueId = useAppSelector(selectActiveVenueId);
    if (!venueId) throw new Error("No active venue id for segment selector");

    const segments =
        useAppSelector((state) => selectSegments(state, venueId)) || {};
    const segmentsArray = Object.values(segments);
    const activeSegment = useAppSelector((state) =>
        selectActiveSegment(state, venueId)
    );
    const dispatch = useAppDispatch();
    const changeActiveSegment = useCallback(
        (segment?: Segment) => {
            dispatch(setActiveSegment({ venueId, data: segment }));
        },
        [dispatch, venueId]
    );
    return {
        segments: segmentsArray,
        activeSegment,
        changeActiveSegment,
    };
}
